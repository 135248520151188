import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AntAlert } from 'antd';
import { DangerFilled, InfoFilled, CircleCheckFilled, ErrorFilled, CloseLarge, CalendarWeek } from '@nackle/origami-icons';
import classNames from 'classnames';
const getIcon = ( status ) => {
    if ( status === 'success' ) {
        return <CircleCheckFilled />;
    }
    // the figma matches the default icon for warning
    if ( status === 'warning' ) {
        return <DangerFilled />;
    }
    if ( status === 'error' ) {
        return <ErrorFilled />;
    }
    if ( status === 'neutral' ) {
        return <CalendarWeek />;
    }
    return <InfoFilled />;
};
const Alert = ( { children, className, description, type, action, ...props } ) => {
    const alertClasses = classNames( {
        [ className ]: className ? true : false,
        [ 'no-description' ]: description ? false : true
    } );
    return (
        <AntAlert
            className={ alertClasses }
            closeIcon={ <CloseLarge aria-label="Close" /> }
            { ...props }
            description={ <>
                <div className='ant-alert-description-inner'>{ description }</div>
                <div>{ action }</div>
            </> }
            icon={ getIcon( type ) }
            type={ type }
            // easiest way to get the action buttons directly below the description
            action={ undefined }
        >
            { children }
        </AntAlert>
    );
};

Alert.propTypes = {
    /** Type of alert */
    type: PropTypes.oneOf( [ 'success', 'warning', 'error', 'neutral', 'info' ] ),
    /** Text to display in Alert */
    description: PropTypes.string,
    /** Buttons to display in Alert */
    action: PropTypes.node
};

export { Alert };