// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 735px) {
  .scroll-up .ant-layout-header.nkl-deck-header.sticky-header {
    right: 0;
    left: 0;
    top: 50px;
    transition: all 250ms ease-out;
  }
  .scroll-up .ant-layout-header.nkl-deck-header.sticky-header.nkl-deck-header-admin {
    top: 100px;
  }
  .scroll-down .ant-layout-header.nkl-deck-header.sticky-header {
    right: 0;
    left: 0;
    top: 0;
    transition: all 250ms ease-out;
  }
  .scroll-down .ant-layout-header.nkl-deck-header.sticky-header.nkl-deck-header-admin {
    top: 50px;
  }
}
.ant-layout-header.nkl-deck-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  padding: 0 1rem;
  line-height: 50px;
  z-index: 1003;
  background: #fff;
  color: #59606D;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), inset 0px -1px 0px #F5F7FA;
}
.ant-layout-header.nkl-deck-header.sticky-header {
  position: fixed;
  z-index: 1004;
  right: 64px;
  width: auto;
  left: unset;
  box-shadow: none;
  display: flex;
  opacity: 0;
  transition: all 250ms ease-out;
}
.ant-layout-header.nkl-deck-header.sticky-header .nkl-deck-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 735px) {
  .ant-layout-header.nkl-deck-header {
    height: 50px;
  }
  .ant-layout-header.nkl-deck-header.sticky-header {
    width: 100%;
    right: 0;
    left: 0;
    top: 50px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05), inset 0px -1px 0px #F5F7FA;
  }
  .ant-layout-header.nkl-deck-header.sticky-header.nkl-deck-header-admin {
    top: 100px;
  }
  .ant-layout-header.nkl-deck-header a {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block;
    width: fit-content;
  }
}
.ant-layout-header.nkl-deck-header .ant-btn {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.ant-layout-header.nkl-deck-header .nkl-deck-popover {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-top: 0;
}
.ant-layout-header.nkl-deck-header .rewards-deck-popover {
  width: 444px;
  padding-top: 0;
}
.ant-layout-header.nkl-deck-header.is-hidden {
  display: none;
}
.ant-layout-header.nkl-deck-header.is-loaded.sticky-header {
  opacity: 1;
  transition: all 250ms ease-out;
}
.ant-layout-header.nkl-deck-header.is-loaded .nkl-deck-delegate-text {
  opacity: 1;
  transform: translateY(0);
}
.ant-layout-header.nkl-deck-header.is-loaded .nkl-deck-avatar-btn.ant-btn {
  padding: 0;
  opacity: 1;
  transition: all 250ms ease-out;
}
.ant-layout-header.nkl-deck-header .deck-company-logo,
.ant-layout-header.nkl-deck-header .deck-logo-placeholder {
  float: left;
  height: 36px;
  margin: 7px 24px 7px 0;
  background: rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 735px) {
  .ant-layout-header.nkl-deck-header .deck-company-logo,
  .ant-layout-header.nkl-deck-header .deck-logo-placeholder {
    margin: 7px 0;
  }
}
.ant-layout-header.nkl-deck-header .menu-button {
  display: none;
  padding: 0;
}
@media screen and (max-width: 1407px) {
  .ant-layout-header.nkl-deck-header .menu-button {
    display: block;
    float: left;
    border: none;
    box-shadow: none;
    margin-top: 9px;
    margin-right: 16px;
  }
}
.ant-layout-header.nkl-deck-header .deck-user-menu.ant-menu:focus {
  box-shadow: none;
}
.ant-layout-header.nkl-deck-header .deck-user-menu .ant-menu-item-disabled .ant-menu-title-content {
  color: #b1baca;
}
.ant-layout-header.nkl-deck-header .deck-user-menu .ant-menu-title-content {
  color: black;
}
.ant-layout-header.nkl-deck-header .deck-company-logo {
  background: transparent;
}
.ant-layout-header.nkl-deck-header .deck-logo-placeholder {
  height: 36px;
  width: 36px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.25);
  animation: fadeInOut 1s infinite;
}
.ant-layout-header.nkl-deck-header .nkl-deck-delegate-text {
  color: #d93321;
  transition: all 250ms ease-out;
  transform: translateY(-100px);
  opacity: 0;
}
.ant-layout-header.nkl-deck-header.nkl-deck-user-info {
  transform: translateX(50%);
  transition: all 250ms ease-out;
}
.ant-layout-header.nkl-deck-header.nkl-deck-user-info button {
  vertical-align: middle;
  margin-right: 8px;
  display: flex;
}
.ant-layout-header.nkl-deck-header.nkl-deck-user-info .ant-btn-text:not(.nkl-deck-avatar-btn) {
  color: #59606D;
}
.ant-layout-header.nkl-deck-header.nkl-deck-user-info .ant-btn-text:hover,
.ant-layout-header.nkl-deck-header.nkl-deck-user-info .ant-btn-text:focus {
  background: #F5F7FA;
  border-color: transparent;
}
.ant-layout-header.nkl-deck-header.nkl-deck-user-info .ant-typography {
  vertical-align: text-top;
}
.ant-layout-header.nkl-deck-header.is-loaded.nkl-deck-user-info {
  opacity: 1;
  transform: translateX(0);
}
@media screen and (max-width: 735px) {
  .ant-layout-header.nkl-deck-header.is-loaded.nkl-deck-user-info {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
.ant-layout-header.nkl-deck-header.is-loaded .nkl-deck-delegate-text {
  opacity: 1;
  transform: translateY(0);
}
.ant-layout-header.nkl-deck-header .mobile-dot {
  display: none;
}
@media screen and (max-width: 400px) {
  .ant-layout-header.nkl-deck-header .mobile-dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #DE1B1B;
    border-radius: 100%;
    position: absolute;
    left: 29px;
    top: 5px;
  }
}
.ant-layout-header.nkl-deck-header .nkl-deck-avatar-btn {
  padding: 0;
  height: 36px;
  width: 36px;
  border: none;
  float: right;
  top: 7px;
  opacity: 0;
  transition: all 250ms ease-out;
}
.ant-layout-header.nkl-deck-header .nkl-deck-avatar-btn:hover {
  color: inherit;
  background-color: inherit;
}
.ant-layout-header.nkl-deck-header .desktop-count {
  display: inline-block;
}
@media screen and (max-width: 400px) {
  .ant-layout-header.nkl-deck-header .desktop-count {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .ant-layout-header.nkl-deck-header .ant-badge {
    display: none;
  }
}
.ant-layout-header.nkl-deck-header .ant-badge-count {
  background: #DE1B1B;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 600;
}
@media screen and (max-width: 400px) {
  .ant-layout-header.nkl-deck-header #deckRewardsMobileBtn .ant-btn-icon,
  .ant-layout-header.nkl-deck-header #deckArcadeMobileBtn .ant-btn-icon,
  .ant-layout-header.nkl-deck-header #deckNotificationsPopoverBtn .ant-btn-icon {
    margin-inline-end: 0;
  }
}
.ant-layout-header.nkl-deck-header .nkl-deck-rewards-btn.ant-btn {
  border-radius: 10px;
  padding: 8px 10px;
  font-weight: 600;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
}
.ant-layout-header.nkl-deck-header .nkl-deck-rewards-btn.ant-btn > .points {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 400px) {
  .ant-layout-header.nkl-deck-header .nkl-deck-rewards-btn.ant-btn > .points {
    margin-left: 8px;
  }
}
.ant-layout-header.nkl-deck-header .notification-bell-icon {
  height: 20px;
}
.nkl-deck-app-root-admin .ant-layout-header.nkl-deck-header {
  top: 50px;
  transition: all 250ms ease-out;
}
.ant-popover.nkl-deck-popover .ant-popover-inner {
  max-height: 75vh;
  overflow: auto;
  padding: 24px;
}
.ant-popover.nkl-deck-popover.deck-notifications-options-menu-outer .ant-popover-inner {
  padding: 0;
}
.ant-popover.nkl-deck-popover.deck-notifications-options-menu-outer .ant-popover-inner button {
  width: 100%;
  display: flex;
  align-items: center;
}
.ant-popover.nkl-deck-popover.deck-notifications-options-menu-outer .ant-popover-inner li:hover {
  background-color: #F5F7FA;
}
.ant-popover.nkl-deck-popover .nkl-deck-popover {
  max-height: none;
  overflow: visible;
}
.ant-popover.nkl-deck-popover.nkl-deck-popover-menu-container .ant-popover-inner-content {
  padding: 0;
  width: auto;
}
.ant-popover.nkl-deck-popover.nkl-deck-popover-menu-container .ant-menu {
  padding: 0;
}
.ant-popover.nkl-deck-popover.nkl-deck-popover-menu-container .ant-menu .ant-menu-item {
  border-radius: 8px;
}
.ant-popover.nkl-deck-popover .ant-popover-inner {
  width: auto;
  height: auto;
  border-radius: 16px;
}
.ant-popover.nkl-deck-popover .nkl-deck-popover-menu {
  border-right: none;
}
.ant-popover.nkl-deck-popover .nkl-deck-popover-menu a:focus {
  text-decoration: underline;
}
.ant-popover.nkl-deck-popover .ant-menu {
  font-size: 16px;
}
.ant-popover.nkl-deck-popover .ant-menu-item-icon {
  min-width: 24px;
  font-size: 24px;
}
.ant-popover.nkl-deck-popover .ant-menu-item.menu-item {
  padding: 10px;
}
.ant-popover.nkl-deck-popover .ant-menu-item.menu-item .ant-menu-title-content {
  margin-left: 1px;
}
.ant-popover.nkl-deck-popover .ant-menu-item svg {
  margin-right: 12px;
  width: 24px;
  fill: currentColor;
  vertical-align: text-bottom;
}
.ant-popover.nkl-deck-popover .ant-layout-sider {
  background: #fff;
}
.ant-popover.nkl-deck-popover .ant-layout-sider-trigger {
  display: none;
}
.ant-popover.nkl-deck-popover .ant-layout-sider-has-trigger {
  padding-bottom: 0;
}
.ant-popover.nkl-deck-popover .ant-layout-sider-has-trigger .ant-menu-inline {
  border-right: none;
}
.nkl-deck-popover .profile-popover .ant-menu .menu-item:hover,
.user-drawer .profile-popover .ant-menu .menu-item:hover {
  background-color: #F5F7FA;
}
.arcade-popover .ant-divider {
  margin: 16px 0;
  border-top: 1px solid #C9D1DF;
}
.arcade-popover .arcade-menu .redeem-option {
  width: 252px;
  height: 52px;
}
@media screen and (max-width: 735px) {
  .arcade-popover .arcade-menu .redeem-option {
    width: 100%;
  }
}
#deckUserPopoverBtn .ant-avatar-string {
  transform: none !important;
  position: static;
  line-height: 36px;
}
#deckNotificationsPopoverBtn .ant-badge {
  margin-left: -16px;
  font-size: 14px;
  font-weight: 600;
}
#deck-rewardsPopoverContainer {
  display: inline-flex;
}
@media screen and (max-width: 735px) {
  #deck-rewardsPopoverContainer {
    display: none;
  }
}
#deck-rewardsMobileContainer {
  display: none;
}
@media screen and (max-width: 735px) {
  #deck-rewardsMobileContainer {
    display: inline-flex;
  }
}
#deck-arcadeMenuContainer {
  display: inline-flex;
}
@media screen and (max-width: 735px) {
  #deck-arcadeMenuContainer {
    display: none;
  }
}
#deck-arcadeMobileContainer {
  display: none;
}
@media screen and (max-width: 735px) {
  #deck-arcadeMobileContainer {
    display: inline-flex;
  }
}
#deck-notificationsMenuContainer {
  display: inline-flex;
}
#deck-notificationsMenuContainer .ant-popover-inner {
  padding-bottom: 0;
}
@media screen and (max-width: 735px) {
  #deck-notificationsMenuContainer {
    display: none;
  }
}
#deck-notificationsMobileContainer {
  display: none;
}
@media screen and (max-width: 735px) {
  #deck-notificationsMobileContainer {
    display: inline-flex;
  }
}
#deck-userMenuContainer {
  display: block;
}
@media screen and (max-width: 735px) {
  #deck-userMenuContainer {
    display: none;
  }
}
#deck-userMobileContainer {
  display: none;
}
@media screen and (max-width: 735px) {
  #deck-userMobileContainer {
    display: block;
  }
}
@media screen and (max-width: 735px) {
  .arcade-deck-drawer .rewards-header-mobile,
  .rewards-drawer .rewards-header-mobile,
  .notifications-deck-drawer .rewards-header-mobile,
  .user-drawer .rewards-header-mobile {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  .arcade-deck-drawer .rewards-header-mobile .gift-code-callout-header,
  .rewards-drawer .rewards-header-mobile .gift-code-callout-header,
  .notifications-deck-drawer .rewards-header-mobile .gift-code-callout-header,
  .user-drawer .rewards-header-mobile .gift-code-callout-header {
    color: #59606D;
    margin-left: 3px;
  }
  .arcade-deck-drawer .rewards-header,
  .rewards-drawer .rewards-header,
  .notifications-deck-drawer .rewards-header,
  .user-drawer .rewards-header {
    display: none;
  }
  .arcade-deck-drawer .rewards-header.display-mobile,
  .rewards-drawer .rewards-header.display-mobile,
  .notifications-deck-drawer .rewards-header.display-mobile,
  .user-drawer .rewards-header.display-mobile {
    display: flex;
    align-items: center;
  }
  .arcade-deck-drawer .notifications-popover-container,
  .rewards-drawer .notifications-popover-container,
  .notifications-deck-drawer .notifications-popover-container,
  .user-drawer .notifications-popover-container {
    width: auto;
  }
}
.user-drawer .ant-drawer-body {
  padding-top: 0;
}
.user-drawer .ant-drawer-body .menu-item {
  padding: 10px;
  border: none;
  width: 100% !important;
}
.rewards-deck-popover .ant-btn,
.arcade-deck-drawer .ant-btn,
.notifications-deck-drawer .ant-btn,
.user-drawer .ant-btn {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.rewards-deck-popover.ant-drawer-content .ant-drawer-header,
.arcade-deck-drawer.ant-drawer-content .ant-drawer-header,
.notifications-deck-drawer.ant-drawer-content .ant-drawer-header,
.user-drawer.ant-drawer-content .ant-drawer-header {
  border-bottom: none;
  padding-top: 18px;
  padding-right: 18px;
}
.rewards-deck-popover.ant-drawer-content .ant-drawer-header button,
.arcade-deck-drawer.ant-drawer-content .ant-drawer-header button,
.notifications-deck-drawer.ant-drawer-content .ant-drawer-header button,
.user-drawer.ant-drawer-content .ant-drawer-header button {
  margin-right: 0;
}
.rewards-deck-popover.ant-drawer-content .ant-drawer-header .ant-drawer-header-title,
.arcade-deck-drawer.ant-drawer-content .ant-drawer-header .ant-drawer-header-title,
.notifications-deck-drawer.ant-drawer-content .ant-drawer-header .ant-drawer-header-title,
.user-drawer.ant-drawer-content .ant-drawer-header .ant-drawer-header-title {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: baseline;
  padding-top: 6px;
}
.nkl-deck-icon {
  fill: currentColor;
}
.nkl-deck-icon.reward-icon {
  width: 19.67px;
  height: 19.67px;
  vertical-align: initial;
}
.nkl-deck-icon path {
  fill: currentColor;
}
.deck-menu-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}
.deck-menu-icon svg {
  width: 100%;
  fill: currentColor;
}
.deck-menu-label-with-icon {
  margin-left: 12px;
  vertical-align: bottom;
  color: #444A53;
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.ant-select-dropdown.budget-dropdown {
  z-index: 100000;
}
.ots-input-button .ant-input-affix-wrapper input.ant-input {
  padding: 0;
}
.nkl-layout-header.nkl-deck-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  padding: 0 1rem;
  line-height: 50px;
  z-index: 10;
  background: #fff;
  color: #808080;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.35);
}
.nkl-layout-header.nkl-deck-header.is-hidden {
  display: none;
}
.nkl-layout-header.nkl-deck-header.is-loaded .nkl-deck-user-info {
  opacity: 1;
  transform: translateX(0);
}
.nkl-layout-header.nkl-deck-header.is-loaded .nkl-deck-delegate-text {
  opacity: 1;
  transform: translateY(0);
}
.nkl-layout-header.nkl-deck-header .deck-company-logo,
.nkl-layout-header.nkl-deck-header .deck-logo-placeholder {
  float: left;
  max-height: 36px;
  margin: 7px 24px 7px 0;
  background: rgba(0, 0, 0, 0.3);
}
.nkl-layout-header.nkl-deck-header .deck-company-logo {
  background: transparent;
}
.nkl-layout-header.nkl-deck-header .deck-logo-placeholder {
  height: 36px;
  width: 36px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.25);
  animation: fadeInOut 1s infinite;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-delegate-text {
  color: #d93321;
  transition: all 250ms ease-out;
  transform: translateY(-100px);
  opacity: 0;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-user-info {
  float: right;
  opacity: 0;
  transform: translateX(50%);
  transition: all 250ms ease-out;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-user-info button {
  vertical-align: middle;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-user-info .nkl-btn-text:not(.nkl-deck-avatar-btn) {
  padding: 4px 12px;
  color: #808080;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-user-info .nkl-btn-text:hover,
.nkl-layout-header.nkl-deck-header .nkl-deck-user-info .nkl-btn-text:focus {
  background: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-user-info .nkl-typography {
  vertical-align: text-top;
}
.nkl-layout-header.nkl-deck-header.is-loaded .nkl-deck-user-info {
  opacity: 1;
  transform: translateX(0);
}
.nkl-layout-header.nkl-deck-header.is-loaded .nkl-deck-delegate-text {
  opacity: 1;
  transform: translateY(0);
}
.nkl-layout-header.nkl-deck-header .nkl-deck-avatar-btn {
  padding: 0;
  height: 36px;
  width: 36px;
  border: none;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  font-size: 24px;
  fill: currentColor;
}
.nkl-layout-header.nkl-deck-header .nkl-deck-icon path {
  fill: currentColor;
}
.nkl-popover.nkl-deck-popover .nkl-popover-inner {
  max-height: 75vh;
  overflow: auto;
}
.nkl-popover.nkl-deck-popover .nkl-deck-popover {
  max-height: none;
  overflow: visible;
}
.nkl-popover.nkl-deck-popover.nkl-deck-popover-menu-container .nkl-popover-inner-content {
  padding: 0;
}
.nkl-popover.nkl-deck-popover .nkl-popover-inner {
  width: auto;
  height: auto;
  border-radius: 16px;
}
.nkl-popover.nkl-deck-popover .nkl-deck-popover-menu {
  border-right: none;
}
.nkl-popover.nkl-deck-popover .nkl-deck-popover-menu a:focus {
  text-decoration: underline;
}
.nkl-popover.nkl-deck-popover .nkl-menu {
  font-size: 16px;
  padding: 15px;
  border-radius: 30px;
}
.nkl-popover.nkl-deck-popover .nkl-menu-item-icon {
  min-width: 24px;
  font-size: 24px;
}
.nkl-popover.nkl-deck-popover .nkl-menu-item svg {
  margin-right: 12px;
  width: 24px;
  fill: currentColor;
  vertical-align: text-bottom;
}
.nkl-popover.nkl-deck-popover .nkl-layout-sider {
  background: #fff;
}
.nkl-popover.nkl-deck-popover .nkl-layout-sider-trigger {
  display: none;
}
.nkl-popover.nkl-deck-popover .nkl-layout-sider-has-trigger {
  padding-bottom: 0;
}
.nkl-popover.nkl-deck-popover .nkl-layout-sider-has-trigger .ant-menu-inline {
  border-right: none;
}
#deckNotificationsPopoverBtn .ant-badge {
  margin-left: 2px;
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
