import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Row, Col, Avatar, Label } from '../';
import Select from 'react-select/async';
import { components } from 'react-select';
import { Search, ChevronDown, Close } from '@nackle/origami-icons';
import { getString } from '@nackle/intl-tools';
import { EmptyState } from './empty-state-illustration';
import classNames from 'classnames';

const env = window.location.hostname.toLowerCase().split( '.' ).find( function ( chunk ) {
    return [ 'ci', 'localhost', 'qa', 'pprd' ].indexOf( chunk ) >= 0;
} );
const baseProfileUrl = `${ window.location.hostname }/api/profile`;
const qaProfileUrl = 'https://qa.api.biw.cloud/v1/profile';

/**
 * Renders an empty state when no search results are found.
 * @returns {JSX.Element} The NoOptions component.
 */
const NoOptions = () => {
    return (
        <div className="empty-state-container">
            <EmptyState />
            <div>{ getString( 'origami.search.noResultsFound' ) }</div>
        </div>
    );
};

/**
 * Custom dropdown indicator component that displays a search icon.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements.
 * @returns {JSX.Element} The DropdownIndicator component.
 */
const DropdownIndicator = ( { children, ...props } ) => (
    <div className="icon-container">
        <Search className="icon" fill="#59606D" />
    </div>
);

/**
 * Custom multi-value label component for displaying selected items.
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The MultiValueLabel component.
 */
const MultiValueLabel = ( props ) => {
    props.data.value = props.data.id;
    const { innerRef, innerProps } = props;
    return (
        <components.MultiValueLabel className="multi-label" key={ props.data.id } ref={ innerRef } { ...innerProps }>
            { `${ props.data.givenName } ${ props.data.surname }` }
        </components.MultiValueLabel>
    );
};

/**
 * Custom multi-value remove component for removing selected items.
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The MultiValueRemove component.
 */
const MultiValueRemove = ( props ) => {
    return (
        <components.MultiValueRemove { ...props }>
            <Close />
        </components.MultiValueRemove>
    );
};

/**
 * Custom value container component with expand/collapse functionality.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements.
 * @returns {JSX.Element} The ValueContainer component.
 */
const ValueContainer = ( { children, ...props } ) => {
    const [ expanded, setExpanded ] = useState( false );
    const [ showChevron, setShowChevron ] = useState( false );
    const [ scrollBar, setScrollBar ] = useState( false );
    const [ placeholder, setPlaceholder ] = useState();
    const valueRef = useRef();

    const containerClasses = classNames( {
        'value': true,
        'expanded': expanded ? true : false,
        'scroll': scrollBar ? true : false
    } );

    useEffect( () => {
        if ( valueRef.current?.clientHeight > 86 ) {
            setShowChevron( true );
        } else {
            setShowChevron( false );
        }
        if ( valueRef.current?.clientHeight > 199 ) {
            setScrollBar( true );
        } else {
            setScrollBar( false );
        }
    }, [ children ] );

    /**
     * Hides the placeholder text.
     */
    const hidePlaceholder = () => {
        const placeholder = document.querySelector( '.search-select__placeholder' );
        if ( placeholder ) {
            placeholder.style.display = 'none';
            setPlaceholder( placeholder );
        } else {
            setTimeout( () => {
                hidePlaceholder();
            }, 100 );
        }
    };

    /**
     * Shows the placeholder text if there are fewer than 3 children.
     */
    const showPlaceholder = () => {
        if ( children.length > 2 ) {
            return;
        } else {
            if ( placeholder ) {
                placeholder.style.display = 'block';
            }
        }
    };

    /**
     * Toggles the expanded state and adjusts the chevron position.
     */
    const expand = () => {
        const chevron = document.querySelector( '.chevron' );
        chevron.style.top = '';
        setExpanded( !expanded );
    };

    return (
        <components.ValueContainer className={ containerClasses } { ...props } >
            <div className="ref-container" ref={ valueRef } onFocus={ hidePlaceholder } onBlur={ showPlaceholder }>
                { children }
                {
                    showChevron &&
                    <div className="search-chevron-container">
                        <div role="button" tabIndex={ 0 } onKeyUp={ ( event ) => { if ( event.key === 'Enter' ) { expand(); } } } onClick={ () => { expand(); } } onTouchEnd={ () => expand() } className='chevron'>
                            <ChevronDown className={ expanded ? 'up' : 'down' } fill={ '#59606D' } />
                        </div>
                    </div>
                }
            </div>
        </components.ValueContainer>
    );
};

/**
 * Custom option component for displaying search results.
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The CustomOption component.
 */
const CustomOption = ( props ) => {
    const { innerProps, innerRef } = props;
    const person = props.data;
    const personDetails = [];
    if ( person.jobTitle ) {
        personDetails.push( person.jobTitle );
    }
    if ( person.department ) {
        personDetails.push( person.department );
    }
    if ( person.country ) {
        personDetails.push( person.country );
    }
    const personString = personDetails.join( ', ' );
    const label = (
        <div className={ person.disabled ? 'cursor-not-allowed' : '' }>
            <div className={ person.disabled ? 'disabled-search-result-wrapper' : 'search-result-wrapper' } role="menuitem" tabIndex={ 0 } ref={ innerRef } key={ person.id } onKeyUp={ ( event ) => { if ( event.key === 'Enter' ) { props.selectOption( event ); } } } onClick={ ( event ) => { props.selectOption( event ); } } { ...innerProps } >
                <div className={ person.disabled ? 'disabled search-result' : 'search-result' }>
                    <span className="avatar-container">
                        <Avatar size={ 'medium' } alt={ getString( 'origami.avatar.alt' ) } className="primary-background-avatar" src={ `${ env === 'localhost' ? qaProfileUrl : baseProfileUrl }/public/images/image/${ person.companyId }/${ person.id }/avatar/170/170` }>{ person.givenName.slice( 0, 1 ) }</Avatar>
                    </span>
                    <div className="right-search-result">
                        <span className="text-container">
                            <div className="search-name">
                                { `${ person.givenName } ${ person.surname }` }
                            </div>
                            <div className="person-details" key={ person.id }>
                                { personString }
                            </div>
                        </span>
                        <div className="disabled-reason">
                            { person.disabledReason }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return label;
};
/**
 * NacklePersonSearch component for searching and selecting people.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.placeholder] - Placeholder text for the search input.
 * @param {string} [props.teamLabel] - Label for the team section.
 * @param {boolean} [props.showTeam] - Whether to show the team section.
 * @param {string} [props.emptyTeamLabel] - Label to show when the team is empty.
 * @param {string} [props.ariaLabel] - Aria label for accessibility.
 * @param {string} [props.className] - Additional CSS class for the component.
 * @param {string} [props.selectClassName] - Additional CSS class for the select element.
 * @param {Array} [props.teamMembers] - Array of team members.
 * @param {function} [props.onSelect] - Callback function when a person is selected.
 * @param {function} props.searchPersons - Function to search for persons.
 * @param {string} props.companyId - ID of the company.
 * @param {Object} props.user - User object containing claims.
 * @param {boolean} [props.hasError] - Indicates whether the component is in an error state.
 * @param {string} [props.errorLabel] - Label to display when the component is in an error state.
 * @param {React.Ref} ref - Ref object for the component.
 * @returns {React.Component} The NacklePersonSearch component.
 */

const NacklePersonSearch = forwardRef( function NackleSearch( props, ref ) {
    const {
        placeholder,
        teamLabel,
        showTeam,
        emptyTeamLabel,
        ariaLabel,
        className,
        selectClassName,
        hasError,
        errorLabel,
        selectedUsers = []
    } = props;
    const mappedUsers = selectedUsers.map( user => {
        user.label = `${ user.givenName } ${ user.surname }`;
        user.value = user.id;
        return user;
    } );
    const [ selected, setSelected ] = useState( mappedUsers );
    const [ options, ] = useState( [] );
    const [ teamMembers, ] = useState( props.teamMembers );
    //get preselected/selected people for tag population
    const selectedIds = selected.map( person => {
        return person.id;
    } );

    //filter out already selected team members so they can't be added twice
    const filtered = [];
    if ( teamMembers ) {
        teamMembers.map( member => {
            if ( !selectedIds.includes( member.id ) ) {
                filtered.push( member );
            }
        } );
    }
    const theme = ( theme ) => ( {
        ...theme,
        spacing: {
            ...theme.spacing,
            controlHeight: 44,
            baseUnit: 1,
        }
    } );
    const selectPerson = ( person ) => {
        setSelected( [ ...person ] );
        if ( typeof props.onSelect === 'function' ) {
            props.onSelect( person );
        }
    };
    const handleSearch = ( value, callback ) => {
        const { searchPersons, companyId, user, disabledLabel } = props;
        searchPersons( value ).then( resp => {
            const persons = resp;
            const companyPersons = persons.map( person => {
                person.companyId = companyId;
                person.value = person.id;
                return person;
            } );
            //can't select yourself so set it disabled.
            const filteredCompanyPersons = companyPersons.map( person => {
                if ( person.id === user.claims.person_id ) {
                    person.disabled = true;
                    person.disabledReason = disabledLabel;
                }
                return person;
            } );
            callback( filteredCompanyPersons );
        } );


    };
    const handleKeyPress = ( e, callback, variables ) => {
        if ( e.key === 'Enter' ) {
            if ( typeof callback === 'function' ) {
                if ( variables ) {
                    callback( variables );
                } else {
                    callback();
                }
            }
        }
    };
    const handleSelect = ( options, action ) => {
        switch ( action.action ) {
            case 'clear':
                selectPerson( [] );
                break;
            case 'remove-value':
                const filtered = selected.filter( person => person.id !== action.removedValue.id );
                selectPerson( filtered );
                break;
            case 'select-option':
                if ( selected.length === 0 ) {
                    selectPerson( options );
                } else if ( selected.length > 0 ) {
                    selected.push( action.option );
                    selectPerson( selected );
                }
                break;

            default:
                console.log( 'unknown action' );
                break;
        }
    };
    const searchClasses = classNames( {
        'content-container': true,
        'search-screen': true,
        [ className ]: className ? true : false
    } );
    return (
        <div className={ searchClasses } ref={ ref }>
            <Row gutter={ [ 24, 24 ] }>
                <Col span={ 24 }>

                    <Select
                        aria-label={ ariaLabel }
                        components={ {
                            DropdownIndicator,
                            Option: CustomOption,
                            MultiValueLabel,
                            LoadingIndicator: null,
                            ValueContainer,
                            MultiValueRemove
                        } }
                        noOptionsMessage={ NoOptions }
                        classNamePrefix={ 'search-select' }
                        styles={ {
                            control: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                flexDirection: 'row-reverse',
                                borderRadius: '8px',
                                border: hasError ? '1px solid #de1b1b' : '1px solid #C9D1DF',
                                '&:hover': {
                                    border: hasError ? '1px solid #de1b1b' : '1px solid #C9D1DF',
                                },
                                '&:focus': {
                                    boxShadow: '0 0 4px 0 rgba(50, 114, 217, 0.75)'
                                }


                            } ),
                            indicatorSeparator: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                display: 'none'
                            } ),
                            indicatorsContainer: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                marginLeft: '10px',
                                marginRight: '10px'
                            } ),
                            valueContainer: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                gap: '4px'
                            } ),
                            multiValue: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                height: '24px',
                                paddingLeft: '10px',
                                backgroundColor: '#F5F7FA',
                                borderRadius: '6px',
                                lineHeight: '24px',
                                fontSize: '16px',
                                overflow: 'hidden'
                            } ),
                            multiValueRemove: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                width: '34px',
                                justifyContent: 'center',
                                '&:hover': {
                                    backgroundColor: '#F5F7FA',
                                    cursor: 'pointer',
                                    color: 'inherit'
                                },
                                'svg': {
                                    width: '16px',
                                    height: '16px',
                                    color: '#59606d'
                                }
                            } )
                        } }
                        theme={ theme }
                        openMenuOnFocus={ false }
                        openMenuOnClick={ false }
                        isClearable={ false }
                        isMulti={ true }
                        autoFocus={ true }
                        className={ selectClassName ? `${ selectClassName } person-search` : 'person-search' }
                        options={ options }
                        value={ selected }
                        onChange={ ( value, action ) => handleSelect( value, action ) }
                        placeholder={ placeholder || 'Search for people in your organization' }
                        loadOptions={ handleSearch } />
                </Col>
            </Row>
            { showTeam &&

                ( filtered && filtered.length > 0 ?
                    <>
                        <div className="team-container">
                            {
                                hasError && errorLabel &&
                                <Label className="error-label sub-description" text={ errorLabel } />
                            }
                            <Label className="sub-description" text={ teamLabel || 'Add people from your team.' } />
                            <Row>
                                <Col span={ 24 }>


                                    { filtered.map( person => {
                                        const personDetails = [];
                                        if ( person.jobTitle ) {
                                            personDetails.push( person.jobTitle );
                                        }
                                        if ( person.department ) {
                                            personDetails.push( person.department );
                                        }
                                        personDetails.push( person.country );
                                        const personString = personDetails.join( ', ' );
                                        return (
                                            <div className={ person.disabled ? 'disabled search-result' : 'search-result' } tabIndex="0" role="menuitem" key={ person.id } onKeyUp={ person.disabled ? () => { } : () => handleKeyPress( event, selectPerson, [ ...selected, person ] ) } onClick={ person.disabled ? () => { } : () => { selectPerson( [ ...selected, person ] ); } }>
                                                <span className="avatar-container">
                                                    <Avatar alt={ getString( 'origami.avatar.alt' ) } size={ 'medium' } className="primary-background-avatar" src={ `${ env === 'localhost' ? qaProfileUrl : baseProfileUrl }/public/images/image/${ props.companyId }/${ person.id }/avatar/170/170` }>{ person.givenName.slice( 0, 1 ) }</Avatar>
                                                </span>
                                                <div className="disabled-text-container">
                                                    <div className="right-search-result">

                                                        <span className="text-container">
                                                            <div className="search-name">
                                                                { `${ person.givenName } ${ person.surname }` }
                                                            </div>
                                                            <div className="person-details" key={ person.id }>
                                                                { personString }
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="disabled-reason">
                                                        { person.disabledReason }
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    } )
                                    }

                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <div className="no-team-container">
                        <EmptyState />
                        <div className="search-text-no-team">{ emptyTeamLabel || 'No Results' }</div>
                    </div>
                ) }

        </div> );
} );
export { NacklePersonSearch };