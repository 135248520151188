// all keys stored in the cache by this SDK will be prefixed with this value
const CACHE_PREFIX = 'branding:';

// cached for 1 minute
const CACHE_DURATION_MIN = 1;


function SDK( baseUrl, fetch, cacheInterface, companyId ) {
    async function _fetchJson( url, options ) {
        if ( options && options.body ) {
            options.headers = {
                ...( options.headers || {} ),
                [ 'Content-Type' ]: 'application/json'
            };
        }

        const res = await fetch( `${ baseUrl }${ url }`, options );
        if ( res.ok ) {
            return await res.json();
        }

        if ( res.headers.get( 'Content-Type' ).indexOf( 'application/json' ) !== -1 ) {
            const data = await res.json();
            throw {
                status: res.status,
                ...data
            };
        }

        const text = await res.text();
        throw text;
    }

    const getKey = ( key ) => {
        if ( companyId ) {
            return `${CACHE_PREFIX}${ companyId }:${ key }`;
        }
        return `${CACHE_PREFIX}${key}`;
    };

    const _cacheGet = ( key ) => cacheInterface.get( getKey( key ) );
    const _cacheSet = ( key, value, ttl ) => cacheInterface.set( getKey( key ), value, ttl );
    const _cacheClear = () => cacheInterface.clear();

    return {
        getColors: async ( options = {} ) => {
            const key = `colors-${ JSON.stringify( options ) }`;
            const cached = _cacheGet( key );
            if ( cached ) {
                return cached;
            }
            const response = await _fetchJson( '/colors', {
                method: 'POST',
                body: JSON.stringify( options )
            } );
            return _cacheSet( key, response, CACHE_DURATION_MIN );
        },
        upsertColors: async ( colors ) => {
            const response = await _fetchJson( '/colors', {
                method: 'PUT',
                body: JSON.stringify( { colors } )
            } );
            _cacheClear();
            return response;
        },
        getElements: async () => {
            const key = 'elements';
            const cached = _cacheGet( key );
            if ( cached ) {
                return cached;
            }

            const response = await _fetchJson( '/elements' );
            return _cacheSet( key, response, CACHE_DURATION_MIN );
        },
        getProperties: async () => {
            const key = 'properties';
            const cached = _cacheGet( key );
            if ( cached ) {
                return cached;
            }

            const response = await _fetchJson( '/properties' );
            return _cacheSet( key, response, CACHE_DURATION_MIN );
        },
        getElementProperties: async () => {
            const key = 'element-properties';
            const cached = _cacheGet( key );
            if ( cached ) {
                return cached;
            }

            const response = await _fetchJson( '/element-properties' );
            return _cacheSet( key, response, CACHE_DURATION_MIN );
        },
        getStyles: async ( options = {} ) => {
            const key = `styles-${ JSON.stringify( options ) }`;
            const cached = _cacheGet( key );
            if ( cached ) {
                return cached;
            }

            const response = await _fetchJson( '/styles', {
                method: 'POST',
                body: JSON.stringify( options )
            } );
            return _cacheSet( key, response, CACHE_DURATION_MIN );
        },
        upsertStyles: async ( styles ) => {
            const response = await _fetchJson( '/styles', {
                method: 'PUT',
                body: JSON.stringify( { styles } )
            } );
            _cacheClear();
            return response;
        },
    };
}

const noCache = {
    get: () => undefined,
    set: ( key, value, lifetimeMins ) => value,
    clear: () => undefined
};


module.exports = function( url, customFetch, cacheInterface = noCache, companyId ) {
    if ( typeof customFetch !== 'function' ) {
        throw new Error( `${ typeof customFetch } is not a function` );
    }

    return new SDK( url, customFetch, cacheInterface, companyId );
};
